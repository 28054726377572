.filters {
	display: flex;
	flex-direction: row;
	margin: 50px 0;
	align-items: center;
	justify-content: center;
    flex-wrap: wrap;
    .filter {
        font-family: 'Syne';
        font-weight: 700;
        color: #1D6154;
        border: solid 1px #1D6154;
        margin : 0px 5px ;
        padding: 10px 20px;
        text-align: center;
        border-radius: 60px ;
        transition: filter 400ms;
        background-color: #FFFEF8;
        cursor: pointer;
        &:hover {
            filter: brightness(0.9);
        }
    }
    .filter-active {
        background-color: #1D6154;
        color: white;
    }
    @media screen and (max-width:500px) {
        margin: 20px 0;
        .filter{
            font-size: 12px;
            margin: 2px;
            padding: 5px 10px;
        }
    }
}
.gallery-container{
    background-color: #fcfaf3;
    border-radius: 20px;
    padding: 5px 0 75px 0;
    margin: 80px 0 100px 0;
    box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.15);
}
.gallery {
	width: 80%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
    margin: auto;
    img {
        width: 100%;
    }
    figure{
        margin: 0;
    }
    @media screen and (max-width: 550px) {
        grid-template-columns: 1fr 1fr;
        figure {
            font-size: 10px;
        }
    }
}
.login-container{
    display: flex;
    gap: 30px;
    margin: 60px 0 80px 0;
    img{
        width: 50%;
        border-radius: 20px;
        box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.12);
    }
    @media screen and (max-width:800px) {
        flex-direction: column;
        img{
            width: 100%;
            border-radius: 10px;
        }
    }
}
.modal-container{
    display: flex;
    gap: 30px;
    margin: 60px 0 80px 0;
    img{
        width: 50%;
        border-radius: 20px;
        box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.18);
    }
    @media screen and (max-width:800px) {
        flex-direction: column;
        img{
            width: 100%;
            border-radius: 10px;
        }
    }
}