@keyframes text-reveal {
    0% {
        clip-path: inset(0 0 150px 0);
        transform: translateY(80px);
        opacity: 0;
    }
    // 40% {
    //     opacity: 0;
    // }
    90% {
        transform: translateY(-1px);
    }
    100% {
        clip-path: inset(0 0 1px 0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }
  
    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
  @keyframes logo-slide {
    0% {
      transform: translateY(1000px) scaleY(1);
      opacity: 1;
    }
    
      22%{
         transform: translateY(0) scaleY(0.6);
      }
    23% {
        transform: translateY(0) scaleY(1);
        opacity: 1;
      }
    27% {
      transform: translateY(0) scaleY(1);
    }
    80% {
      transform: translateY(0) scaleY(1);
    }
    85% {
      transform: translateY(30px) scaleY(0.7);
    }
    90% {
        transform: scaleY(1.2);
    }
    100% {
      transform: translateY(-1000px) scaleY(1);
      opacity: 1;
    }
  }

  @keyframes load-screen {
    0% {
        transform: translateY(0);
        background-color: rgb(201, 190, 226);
    }
    20% {
        transform: translateY(0);
    }
    78%{
        background-color: rgb(184, 226, 235);
    }
    80% {
        transform: translateY(0);
        opacity: 1;
        
    }
    100% {
        transform: translateY(-1000px);
        opacity: 1;
    }
  }

  @keyframes circle-grow {
    0% {
        opacity: 1;
    }
    90%{
        opacity: 1;
        scale: 1
    }
    100% {
        scale: 7;
        opacity: 0;
    }
  }


@keyframes write {
    0% {
        stroke-dashoffset: 1000;
    }
    50% {
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dashoffset: -1000;
    }
}

  @keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100vw);
    }
    // 100% {
    //     transform: translateX(0);
    // }
  }