nav {
    display: flex;
    flex-direction: row-reverse;
    gap: 35px;
    justify-content: space-between;
    padding: 0 40px;
    color: #211349;
    position: fixed;
    right: 0;
    left: 0;
    background-color: #FEFBF7;
    z-index: 999;
    div {
        display: flex;
        flex-direction: row;
        gap: 35px;
        justify-content: flex-end;
        align-items: center;
    }
    a {
        text-transform: uppercase;
        color: #211349;
        text-decoration: none;
        transition: color 400ms ease, font-weight 500ms ease;
        &:hover {
            color: #8d6dae;
            cursor: pointer;
            font-weight: 600;
        }
    }
    .special-nav{
        background-color: #DEDEFF  ;
        padding: 10px 25px;
        border-radius: 50px;
        transition: box-shadow 900ms cubic-bezier(.18,.97,.53,1), font-weight 500ms cubic-bezier(.18,.97,.53,1);
            &:hover {
                background: linear-gradient(133deg, rgba(222,222,255,1) 35%, rgba(148,241,246,1) 100%);
                box-shadow: 2px 3px 20px 3px rgba(66, 68, 90, 0.22);
                color: #211349;
                font-weight: 600;
            }
    }
    svg {
        height: 60px;
        width: fit-content;
        padding: 20px;
    }
    .line {
        width: 2px;
        background-color: #370965;
        opacity: 0.2;
        height: 35px;
        border-radius: 50px;
    }
    @media screen and (max-width: 660px) {
        padding: 10px;
        gap: 0;
        a{
            font-size: 14px;
            text-wrap: nowrap;
        }
        svg {
            padding: 10px;
            height: 40px;
        }
    }
    @media screen and (max-width: 600px) {
        .nav-container{
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
        }
    }
    @media screen and (max-width: 350px) {
        a{
            font-size: 12px;
        }
        .nav-container{
            div{
                gap: 15px;
            }
        }
    }
}