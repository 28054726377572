.back-container{
    padding: 20px;
    svg{
        height: 30px;
        transform: rotate(180deg);
        cursor: pointer;
        margin: 0 40px;
        transition: margin 300ms ease;
        &:hover{
            margin: 0 25px;
        }
    }
    @media screen and (max-width: 500px) {
        padding: 10px;
        svg{
            margin: 0 20px;
            height: 20px;
            &:hover{
                margin: 0 10px;
            }
        }
    }
    @media screen and (max-width: 400px) {
        padding: 10px;
        svg{
            margin: 0 10px;
            height: 20px;
            &:hover{
                margin: 0 10px;
            }
        }
    }
}
// .to-top-container{
//     position:absolute;
//     bottom: 10vh;
//     z-index: 1;
// }
.link-container {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    p{
        
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
    }
}
.to-top{
    
    background-color: #DEDEFF;
    padding: 20px;
    width: 30px;
    border-radius: 50%;
    box-shadow: 2px 3px 15px 3px rgba(66, 68, 90, 0.20);
    transition: background-color 400ms ease, box-shadow 400ms ease;
    svg{
        transform: rotate(270deg);
        margin: auto;
        height: 20px;
    }
    &:hover{
        background-color: #d1d1f4; 
        box-shadow: 2px 3px 30px 3px rgba(66, 68, 90, 0.30);
    }
}
.show {
    opacity: 1;
    visibility: visible;
  }