.card {
    width: 790px;
    height: 580px;
    border-radius: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 50px;
    padding: 0 40px 0 60px;
    color: #211349;
    img {
        width: 350px;
        border-radius: 20px 20px 0 0;
        box-shadow: 5px 2px 30px 0px rgba(83, 86, 113, 0.38);
    }
    .project-details {
        display: flex;
        flex-direction: column;
        gap: 25px;
        justify-content: center;
        height: 100%;
        width: 100%;
        .description-container{
            overflow: hidden;
        }
        .arrow {
            height: 13px;
            object-fit: contain;
            box-shadow: none;
            transform: rotate(180deg);
            margin-top: -20px;
        }
        p {
            margin: 0;
            font-size: 18px;
            line-height: 24px;
        }
        .icon-container {
            display: flex;
            align-items: flex-start;
            gap: 30px;
            img {
                height: 60px;
                box-shadow: none;
                width: fit-content;
                border-radius: 0;
            }
        }
        .button-container {
            margin-top: 20px;
            display: flex;
            gap: 20px;
        }
        a{
            text-decoration: none;
            text-wrap: nowrap;
        }
        button {
            border-radius: 50px;
            padding: 15px 25px;
            border: none;
            font-size: 16px;
            font-family: syne;
            text-transform: uppercase;
            background-color: white;
            opacity: 0.6;
            box-shadow: 5px 2px 30px 0px rgba(83, 86, 113, 0.23);
            transition: background-color 300ms ease, opacity 500ms ease, box-shadow 900ms ease, font-weight 500ms ease;
            display: flex;
            justify-content: center;
            gap: 10px;
            color: #211349;
            cursor: pointer;
            &:hover {
                background-color:#c7eded;
                opacity: 1;
                box-shadow: 5px 6px 30px 0px rgba(83, 86, 113, 0.42);
                font-weight: 600;
            }
            svg {
                height: 16px;
            }
        }
        .see-details{
            width: 100%;
            background-color: none;
            svg{
                margin-top: 3px;
                height: 14px;
            }
        }
    }
    h3 {
        font-size: 35px;
        margin: 0;
    }
    @media screen and (max-width: 1020px) {
        width: auto;
    }
    @media screen and (max-width: 950px) {
        padding: 30px 20px 0 20px;
        gap: 20px;
        height: auto;
        img {
            width: 300px;
        }
        h3{
            font-size: 28px;
        }
        .project-details{
            p{
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    @media screen and (max-width: 800px) {
        .project-details{
            .button-container{
                gap: 10px;
            }
            button{
                font-size: 14px;
                svg{
                    height: 14px;
                }
            }
        }
    }
    @media screen and (max-width:730px) {
        img{
            width: 250px;
        }
        .project-details{
            gap: 20px;
        }
    }
    @media screen and (max-width:685px) {
        img{
            width: 200px;
        }
        .project-details{
            gap: 25px;
            .icon-container{
                img{
                    height: 40px;
                }
            }
        }
    }
    @media screen and (max-width: 590px) {
        .project-details{
            .button-container{
                flex-direction: column;
                gap: 20px;
                margin: 0;
                button{
                    width: 100%;
                }
            }
            .icon-container{
                gap: 20px;
            }
        }
    }
    @media screen and (max-width: 465px) {
        flex-direction: column-reverse;
        align-items: center;
        .project-details{
            .icon-container{
                img{
                    height: 60px;
                    width: auto;
                }
            }
        }
    }
    @media screen and (max-width: 360px) {
        .project-details{
            .icon-container{
                img{
                    height: 53px;
                }
            }
        }
    }
}