@import '../../animations/animation.scss';
.main{
    display: flex;
    align-items: center;
    padding: 0 8%;
    height: 90vh;
    .art-container{
        position: absolute;
        z-index: -1;
        right: 0;
        padding-top: 80px;
        img{
            height: 540px;
            padding-bottom: 120px;
        }
    }
    @media screen and (max-width: 1370px) {
        .art-container{
            img{
                height: 450px;
                padding-bottom: 150px;
            }
        }
    }
    @media screen and (max-width: 1250px) {
        .art-container{
            img{
                height: 380px;
                padding-bottom: 250px;
            }
        }
    }
    @media screen and (max-width: 1220px) {
        .art-container{
            position: static;
            img{
                padding-bottom: 0px;
            }
        }
    }
    @media screen and (max-width: 1175px) {
        flex-direction: column-reverse;
        justify-content: flex-end;
        padding: 80px 8%;
        height: 100%;
        .art-container{
            img{
                height: 350px;
            }
        }
    }
    @media screen and (max-width: 800px) {
        padding: 50px 8%;
        .art-container{
            img{
                height: 300px;
            }
        }
    }
    @media screen and (max-width: 600px) {

        .art-container{
            img{
                height: 250px;
            }
        }
    }
    @media screen and (max-width: 380px) {
        .art-container{
            img{
                height: 200px;
            }
        }
    }
    
}
.main-container{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 80px;
    h1{
        font-size: 52px;
        font-weight: 400;
        color: #211349;
        margin: 10px 0;
        line-height: 88px;
    }
    .first{
        animation: text-reveal;
        animation-duration: 1800ms;
        animation-fill-mode: backwards;
        animation-timing-function: ease-out;
        animation-delay: none;
    }
    .second{
        animation: text-reveal;
        animation-duration: 1800ms;
        animation-delay: 1300ms;
        animation-fill-mode: backwards;
        animation-timing-function: ease-out;
    }
    .heavy{
        font-weight: 700;
    }
    a{
        text-decoration: none;
        width: fit-content;
    }
    button{
        background-color: #DEDEFF     ;
        padding: 20px 60px;
        border-radius: 50px;
        border: none;
        font-size: 18px;
        font-family: syne;
        text-transform: uppercase;
        margin: 50px 0 80px 0;
        color: #211349;
        animation: animate-pop;
        animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
        animation-duration: 350ms;
        animation-delay: 3600ms;
        animation-fill-mode: backwards;
        transition: box-shadow 900ms cubic-bezier(.18,.97,.53,1), font-weight 500ms cubic-bezier(.18,.97,.53,1);
        text-wrap: nowrap;
        display: flex;
        gap: 20px;
        align-items: center;
        svg {
            height: 14px;
            transition: transform 500ms cubic-bezier(.18,.97,.53,1);
        }
        &:hover {
            background: linear-gradient(133deg, rgba(222,222,255,1) 35%, rgba(148,241,246,1) 100%);
            box-shadow: 2px 3px 35px 3px rgba(66, 68, 90, 0.25);
            cursor: pointer;
            font-weight: 600;
            svg{
                transform: rotate(90deg);
            }
        }
    }
    @media screen and (max-width: 950px) {
        h1{
            font-size: 46px;
            line-height: 76px;
        }
    }
    @media screen and (max-width: 800px) {
        padding: 20px 0 80px 0;
        h1{
            font-size: 38px;
            line-height: 60px;
        }
    }
    @media screen and (max-width: 400px) {
        
        h1{
            font-size: 30px;
            line-height: 52px;
        }
        button {
            font-size: 16px;
        }
    }
}
h2 {
    font-size: 50px;
    color: #211349;
    min-width: 350px;
    line-height: 60px;
    font-weight: 600;
    margin: 0 0 20px 0;
    @media screen and (max-width: 1020px) {
        font-size: 38px;
        min-width: auto;
    }
    @media screen and (max-width: 400px) {
        font-size: 30px;
        line-height: 44px;
    }
    @media screen and (max-width: 360px) {
        min-width: auto;
    }
} 
#works {
    scroll-behavior: smooth;
}
.works-title  {
    padding: 100px;
    position: sticky;
    top: 100px;
    @media screen and (max-width: 1550px) {
        padding: 0;
        top: 150px;
    }
}
.glass-overlay{
    scroll-margin-top: 100px;
    background-color: rgb(235, 240, 240 , 50%);
    border-radius: 80px;
    padding: 50px 40px 50px 60px;
    box-shadow: 6px 3px 48px -15px rgba(66, 75, 90, 0.3);
    display: flex;
    align-items: flex-start;
    .card-wrapper {
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
        margin: 0 30px 0 40px;
        a{
            color: #211349;
            text-decoration: none;
        }
    }
    @media screen and (max-width: 1350px) {
        .card-wrapper{
            margin: 0;
        }
    }
    @media screen and (max-width: 1300px) {
        padding: 30px 45px;
        flex-direction: column;
        margin: 0;
        .works-title{
            position: static;
            padding-top: 30px;
        }
    }
    @media screen and (max-width:685px) {
        padding: 20px 10px;
        border-radius: 50px;
    }
}
#about {
    scroll-behavior: smooth;
}
.about-container {
    scroll-margin-top: 100px;
    padding: 150px 10%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 80px;
    color: #211349;
    .img-box{
        display: flex;
        height: 300px;
        
        img{
            border-radius: 15px;
        }
    }
    h2 {
        margin-bottom: 40px;
    }
    p {
        font-size: 20px;
        line-height: 30px;
    }
    @media screen and (max-width: 1150px) {
        .img-box{
            height: 300px;
        }
    }
    @media screen and (max-width: 1000px) {
        gap: 50px;
        .img-box{
            height: 250px;
            width: auto;
        }
        p{
            font-size: 18px;
            line-height: 26px;
        }
    }
    @media screen and (max-width: 800px) {
        padding: 80px 4% 80px 6% ;
        gap: 30px;
    }
    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
        padding: 90px 8%;
        p{
            font-size: 20px;
            line-height: 30px;
        }
        .img-box{
            height: fit-content;
        }
        img{
            display: block;
            object-fit: cover;
            width: 100%;
        }
        
        h2{
            min-width: 0;
        }
    }
    @media screen and (max-width: 360px) {
        padding: 80px 10px;
        div{
            width: 100%;
        }
    }
}
.skills {
    scroll-margin-top: 100px;
    display: flex;
    flex-direction: column;
    padding: 50px 10%;
    color: #211349; 
        &-container{
        display: flex;
        flex-direction: column;
        padding: 100px 0 140px 0;
        align-items: center;
        gap: 90px;
            .section{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                font-size: 20px;
                line-height: 30px;
                gap: 90px;
                .card-text{
                    width: 45%;
                }
                .img {
                    img{
                        object-fit: cover;
                        width: 450px;
                        border-radius: 20px;
                    }
                }
                h4{
                    font-size: 22px;
                    margin: 0;
                }
                .title {
                    display: flex;
                    align-items: flex-end;
                    gap: 15px;
                    h3 {
                        margin: 0;
                    }
                }
                .dev {
                    flex-direction: row-reverse;
                }
                .line{
                    height: 2px;
                    width: 100%;
                    background-color: #211349;
                    opacity: 0.2;
                    border-radius: 50px;
                    margin-bottom: 10px;
                }
            }
            .dev {
                flex-direction: row-reverse;
            }
        }
   @media screen and (max-width: 1000px) {
    &-container{
        .section{
            .img{
                img{
                    width: 300px;
                }
            }
        }
    }
   }
   @media screen and (max-width: 800px) {
    &-container{
        .section{
            flex-direction: column;
            gap: 30px;
            .card-text{
                width: 100%;
            }
        }
    }
   }
   @media screen and (max-width: 800px) {
    h2{
        min-width: 0;
    }
    &-container{
        padding: 40px 0 40px 0;
    }
   }
}
.cta {
    @media  screen and (max-width: 950px) {
        .buttons{
            a {
                button{
                    font-size: 14px;
                    width: 80%;
                }
            }
        }
    }
    @media  screen and (max-width: 700px) {
        flex-direction: column-reverse;
        gap: 80px;
        .buttons{
            a{
                button{
                    width: 100%;
                }
            }
        }
        .work-together {
            h2{
                min-width: 0;
            }
            
        }
    }
    @media screen and (max-width: 500px) {
        .buttons{
            margin-top: 60px;
        }
    }
}
