@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

.cta {
    scroll-margin-top: 100px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 150px 120px;
        color: #211349;
        p {
            font-size: 20px;
            line-height: 30px;
            padding: 20px 0 0 0;
            margin: 0;
        }
        .name{
            margin-top: 20px;
            font-size: 38px;
            font-family: "Satisfy", cursive;
        }
        a{
            text-decoration: none;
        }
        button{
            height: 70px;
            padding: 0 40px 0 40px;
            font-family: syne;
            font-size: 16px;
            border: none;
            border-radius: 50px;
            margin-bottom: 30px;
            background-color: #DEDEFF;
            box-shadow: 2px 3px 13px 3px rgba(66, 68, 90, 0.04);
            transition: font-weight 500ms cubic-bezier(.18,.97,.53,1), box-shadow 700ms cubic-bezier(.18,.97,.53,1), width 500ms cubic-bezier(.18,.97,.53,1);
            display: flex;
            align-items: center;
            gap: 20px;
            justify-content: flex-start;
            width: 370px;
            color: #211349;
            cursor: pointer;
            &:hover {
                background: linear-gradient(133deg, rgba(222,222,255,1) 35%, rgba(148,241,246,1) 100%);
                box-shadow: 2px 3px 20px 3px rgba(66, 68, 90, 0.15);
                font-weight: 600;
                width: 395px;
            }
            svg {
                height: 20px;
            }
            span{
                svg {
                    height: 15px;
                }
            }
        }
        .buttons {
            display: flex;
            flex-direction: column;
        }
        .work-together{
            width: 55%;
        }
        @media screen and (max-width: 1120px) {
            padding: 80px 20px 60px 20px;
        }
        @media screen and (max-width: 430px) {
            padding: 80px 20px 60px 20px;
            gap: 0;
            div{
                width: 100%;
            }
            .work-together{
                width: 100%;
            }
            button{
                font-size: 16px;
                height: 60px;
            }
        }
    }


.footer {
    background-color: black;
    color: white;
    display: flex;
    
    justify-content: space-between;
    &-nav {
        padding: 10px;
        display: flex;
        text-transform: uppercase;
        gap: 80px;
        font-size: 18px;
        align-items: center;
        justify-content: center;
        text-wrap: nowrap;
        width: 100%;
        a{
            transition: color 400ms ease, font-weight 500ms ease;
            color: white;
            text-decoration: none;
            &:hover{
                color: #a984cf;
                cursor: pointer;
                font-weight: 600;
            }
        }
    }
    svg {
        color: white;
        margin: 50px 0 50px 80px;
        height: 70px;
        transition: opacity 400ms ease;  
    } 
    .socials {
        display: none;
        flex-direction: row;
        align-items: center;
        gap: 30px;
        padding: 0 150px 0 0;
        img {
            height: 40px;
            transition: filter 400ms ease;
            &:hover {
                filter: brightness(60%);
                cursor: pointer;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        &-nav {
            padding: 20px;
            gap: 15%;
            font-size: 16px;
            align-items: center;
            justify-content: center;
        } 
        a {
            svg {
             margin: 50px 0 50px 0;
             height: 50px;
         } 
        }
        .socials {
            gap: 20px;
            padding: 0 100px 0 0;
            img {
                height: 30px;
            }
        }
    }
    @media screen and (max-width: 510px) {
        svg{
            height: 40px;
            margin: 40px 0 40px 10px;
        }
    }
    @media screen and (max-width: 400px) {
        &-nav{
            flex-direction: column;
        }
    }
}