@import '../../animations/animation.scss';

.scroller-container {
    background-color: #ffffff;
    box-shadow: 2px 3px 40px 3px rgba(66, 68, 90, 0.10);
    overflow: hidden;
    display: flex;
    padding: 35px 0 35px 0;
    gap: 20px;
    div {
        display: flex;
        justify-content: space-between;
        animation: scroll 18s infinite linear;
    }
    p {
        font-size: 24px;
        font-weight: 700;
        text-wrap: nowrap;
    }
    img {
        height: 60px;
        padding: 0 45px 0 45px;
    }
    @media screen and (max-width: 480px) {
        gap: 0;
        img {
            height: 30px;
            padding: 0 20px 0 20px;
        }
        div{
            animation: scroll 10s infinite linear;
        }
    }
}