@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  margin: auto;
  font-family: syne;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(255, 251, 248);
  background-image:
    radial-gradient(900px at 100% 60%, rgba(179, 179, 238, 0.565) -100px, transparent 70%),
    radial-gradient(800px at 10% 40%, rgba(190, 246, 249, 0.62) -100px, transparent 70%);
  width: 100%;
  height: 180vh;
}


