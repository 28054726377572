.photos-div{
    display: flex;
    gap: 100px;
    padding: 40px 0;
    img{
        border-radius: 20px;
        object-fit: cover;
        height: 350px;
        box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.18);
    }
    .url-container{
        background-color: rgb(244, 245, 246);
        border-radius: 10px;
        p{
            font-size: 14px;
            color: #79728d;
            text-wrap: nowrap;
            span{
                color: #443767;
                font-weight: 500;
                background-color: rgb(169, 221, 235);
            }
        }
        .url-bar{
            background-color: rgb(221, 225, 229);
            padding: 0 20px;
            margin: 0 15px;
            border-radius: 50px;
        }
    }
    @media screen and (max-width:1400px) {
        img{
            width: 100%;
        }
        .url-container{
            p{
                font-size: 12px;
            }
        }
    }
    @media screen and (max-width:1220px) {
        img{
            width: 100%;
            height: auto;
        }
    }
    @media screen and (max-width:1100px) {
        img{
            width: 100%;
            height: auto;
        }
        .url-container{
            p{
                font-size: 10px;
            }
        }
    }
    @media screen and (max-width:950px) {
        gap: 30px;
        .url-container{
            .url-bar{
                padding: 0 10px;
            }
        }
    }
    @media screen and (max-width:800px) {
        flex-direction: column;
    }
    @media screen and (max-width:400px) {
        .url-container{
            p{
                font-size: 8px;
            }
        }
    }
}
.router-section {
    margin-top: 150px;
    img {
        border-radius: 20px;
        width: 50%;
        height: fit-content;
        box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.18);
        object-fit: cover;
    }
    .div{
        display: flex;
        gap: 50px;
        padding-right: 40px;
    }
    @media screen and (max-width: 800px) {
        margin-top: 80px;
        .div{
            flex-direction: column;
            padding-right: 0;
        }
        img{
            width: 100%;
            border-radius: 10px;
        }
    }
    @media screen and (max-width: 500px) {
        margin-top: 30px;
    }
}
.workflow{
    @media screen and (max-width:1220px) {
        img{
           height: 300px; 
        }
        
    }
    @media screen and (max-width:900px) {
        img{
           height: 200px; 
        }
        
    }
    @media screen and (max-width:800px) {
        flex-direction: row;
        img{
           height: 200px; 
        }
        
    }
    @media screen and (max-width:600px) {
        flex-direction: column;
        align-items: center;
        img{
           height: 230px;
           width: fit-content; 
        }
        
    }
    @media screen and (max-width:480px) {
        flex-direction: column;
        img{
           height: 190px;
           width: fit-content; 
           border-radius: 10px;
        }
        
    }
    @media screen and (max-width:400px) {
        flex-direction: column;
        img{
           height: 150px;
           width: fit-content; 
           border-radius: 10px;
        }
        
    }
}