.project-banner {
    width: 100%;
}
.page {
    background-color: rgb(255, 254, 253);
    padding: 80px 18%;
    position: relative;
    top: -50px;
    border-radius: 50px;
    box-shadow: 6px -3px 40px -15px rgba(66, 75, 90, 0.8);
    @media screen and (max-width:1400px) {
        padding: 80px 12%;
    }
    @media screen and (max-width:550px) {
        padding: 20px 6%;
    }
    h1{
        font-size: 36px;
        margin-bottom: 30px;
        color: #211349;
    }
    h2{
        font-size: 34px;
        width: fit-content;
        @media screen and (max-width: 400px) {
            font-size: 28px;
            min-width: auto;
        }
    }
    .tag-container{
        display: flex;
        gap: 15px;
        text-transform: uppercase;
        font-size: 14px;
        flex-wrap: wrap;
        @media screen and (max-width:450px) {
            font-size: 10px;
            gap: 10px;
        }
        .tag{
            padding: 8px 20px;
            border-radius: 50px;
            color: #211349;
            text-wrap: nowrap;
        }
        .ux {
            background-color: rgb(213, 252, 239);
        }
        .ui {
            background-color: rgb(216, 249, 249);
        }
        .graphic {
            background-color: rgb(240, 230, 255);
        }
        .wireframes {
            background-color: rgb(247, 227, 255);
        }
        .sass {
            background-color: rgb(255, 227, 248);
        }
        .html {
            background-color: rgb(249, 224, 216);
        }
        .webdev {
            background-color: rgb(198, 250, 238);
        }
        .react {
            background-color: rgb(206, 249, 248);
        }
        .css {
            background-color: rgb(217, 227, 250);
        }
        .js {
            background-color: rgb(255, 247, 219);
        }
    }
    .section{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 80px;
        margin: 60px 0;
        @media screen and (max-width: 1050px) {
            gap: 0;
        }
        @media screen and (max-width: 800px) {
            flex-direction: column;
            margin: 30px 0;
        }
    }
    p{
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 20px;
        color: #211349;
        @media screen and (max-width: 450px) {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 10px;
        }
    }
    .image {
        box-shadow: none;
        margin: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
           height: 550px; 
        }
        @media screen and (max-width: 1000px) {
            margin: 0;
            img{
                height: 400px;
            }
        }
        @media screen and (max-width: 800px) {
            img{
                height: 100%;
                width: 400px;
                margin: auto;
            }
        }
        @media screen and (max-width: 500px) {
            img{
                width: 300px;
            }
        }
        @media screen and (max-width: 350px) {
            img{
                width: 280px;
            }
        }
    }
    .screenshot{
        margin: 80px 0;
        img{
            width: 100%;
            border-radius: 20px;
            box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.18);
        }
    }
    input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }
    
    label {
        cursor: pointer;
        text-indent: -9999px;
        width: 100px;
        height: 50px;
        background: rgb(177, 175, 189);
        display: block;
        border-radius: 100px;
        position: relative;
        @media screen and (max-width: 550px) {
            height: 30px;
            width: 50px;
        }
    }
    
    label:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 40px;
        height: 40px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
        @media screen and (max-width: 550px) {
            width: 20px;
            height: 20px;
            top: 2;
            
        }
    }
    
    input:checked + label {
        background: #ae84ee;
    }
    
    input:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }
    
    label:active:after {
        width: 50px;
        @media screen and (max-width: 550px) {
            width: 25px;
        }
    }
    .hidden {
        opacity: 0;
    }
    .toggle {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        h3{
            margin: 0 10px 0 30px;
            width: 120px;
            color: #211349;
            @media screen and (max-width: 550px) {
                width: fit-content;
                margin: 0 0 0 10px;
            }
            @media screen and (max-width: 370px) {
                font-size: 16px;
            }
        }
        @media screen and (max-width: 550px) {
            justify-content: space-between;
        }
    }
    .toggle-img {
        display: flex;
        justify-content: center;
        margin-bottom: 80px;
        img{
            width: 500px;
            box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.18);
            border-radius: 20px;
            margin: 30px;
            @media screen and (max-width: 500px) {
                width: 350px;
            }
            @media screen and (max-width: 400px) {
                width: 300px;
            }
        }
    }
    .blog-container {
        display: flex;
        gap: 20%;
        width: 70%;
        margin: auto;
        padding: 60px 0 100px 0;
        img {
            width: 100%;
            border-radius: 20px;
            box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.18);
        }
        @media screen and (max-width: 750px){
            padding: 50px 0 80px 0;
            width: 100%;
            gap: 10%;
            img{
                border-radius: 10px;
            }
        }
    }
    .tools-container {
        display: flex;
        gap: 30px;
        margin: 0 0 80px 0;
        img {
            width: 480px;
            margin: 0 -30px 0 0;
        }
        @media screen and (max-width: 1150px){
            img{
                width: 350px;
            }
        }
        @media screen and (max-width: 950px){
            flex-direction: column-reverse;
            align-items: center;
            margin: 0;
            gap: 0;
            img{
                width: 100%;
                margin: auto;
            }
        }
    }
    .content-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 80px 0;
        img {
            width: 30%;
            border-radius: 15px;
            box-shadow: 5px 7px 40px 5px rgba(66, 68, 90, 0.18);
        }
        @media screen and (max-width: 450px) {
            
            gap: 25px;
            margin: 30px 0;
            img{
                width: 100%;
            }
        }
        @media screen and (max-width: 750px) {
            flex-direction: column;
            gap: 25px;
            margin: 30px 0;
            img{
                width: 80%;
                margin: auto;
            }
        }
    }
}